<template>
  <div class="experiences" data-view>
    <hero-with-image
      title='<div class="saolitalic">a place where people will learn to be mindful in <span class="whitespace-nowrap">daily life</span>, and
          <span class=""
            >ultimately to create humanity’s behavior change.</span
          ></div>'
      preDescription="<span class='saoltext'>‘neera’</span> creates spaces to offer deeper thoughts and connections through nature, design, and mindfulness."
      description="our modern meeting rooms and convention hall are equipped with the latest standard audio and visual facilities and also cater to live-streaming. we offer eco-meeting packages to stand along with our eco-conscious living throughout the <span class='saoltext'>‘neera’</span> concept."
      :imageUrl="require('@/assets/experiences/spaces/SPACES_01.png')"
      class="mb-18 lg:mb-20 lg:mt-8"
    />
    <neera-grid class="mb-18 lg:mb-20">
      <neera-grid-row
        data-aos="fade-up"
        v-for="(item, index) in spaces"
        :key="index"
        :id="item.link ? item.link : ''"
      >
        <neera-detail-row
          :classImage="`img-para w-full rounded-tr-neera lg:rounded-tr-np-lg`"
          :imageUrl="item.image_url"
        >
          <p
            :id="item.name"
            class="text-np-glimpse saoltext font-light text-np-48"
          >
            {{ item.title
            }}<sup class="text-np-base lg:text-np-24">{{
              `(${item.type})`
            }}</sup>
          </p>
          <p class="robototext text-np-xl text-np-green font-light">
            {{ item.description }}
          </p>
          <ul class="neera-text-green">
            <li>area : {{ item.area }} sqm</li>
            <li v-if="item.note">note : {{ item.note }}</li>
            <li v-for="(addon, index2) in item.addons" :key="index2">
              {{ addon }}
            </li>
          </ul>
        </neera-detail-row>
      </neera-grid-row>
    </neera-grid>
    <neera-container data-aos="fade-up" class="my-18 lg:my-20">
      <div class="flex flex-col">
        <div class="flex flex-col gap-y-4">
          <div class="order-1">
            <img
              class="flex lg:hidden w-screen overflow-x-scroll"
              src="@/assets/experiences/spaces/capacity.svg"
            />
            <img
              class="hidden lg:flex w-full"
              src="@/assets/experiences/spaces/capacity.svg"
            />
          </div>
          <div class="order-2 space-y-2">
            <p
              class="text-center lg:text-right font-light text-np-green text-np-14"
            >
              download PDF for more detail and floor plan
            </p>
            <div class="grid grod-cols-1 lg:grid-cols-2 gap-x-10 lg:gap-x-16">
              <div
                class="flex justify-center lg:justify-end lg:col-start-2 col-span-1 gap-x-8"
              >
                <neera-solid-button
                  class="w-1/2"
                  text="download pdf"
                  staticFileSlug="neeraretreathotel-spaces-floor-plan"
                  :linkTo="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </neera-container>
    <other-experiences data-aos="fade-up" exclude-path="/experiences/spaces" />
  </div>
</template>

<script>
import OtherExperiences from "@/components/OtherExperiences.vue";
import HeroWithImage from "../../components/HeroWithImage.vue";
import NeeraContainer from "../../components/layout/NeeraContainer.vue";
import NeeraGrid from "../../components/layout/NeeraGrid.vue";
import NeeraGridRow from "../../components/layout/NeeraGridRow.vue";
import NeeraDetailRow from "../../components/layout/NeeraDetailRow.vue";
import NeeraSolidButton from "../../components/NeeraSolidButton.vue";
import AOS from "aos";

export default {
  components: {
    OtherExperiences,
    HeroWithImage,
    NeeraContainer,
    NeeraGrid,
    NeeraGridRow,
    NeeraDetailRow,
    NeeraSolidButton,
  },
  name: "Spaces",
  created() {
    const title = "a place where people will learn to be mindful in daily life, and ultimately to create humanity’s behavior change.";
    const description =
      "‘neera’ creates spaces to offer deeper thoughts and connections through nature, design, and mindfulness. our modern meeting rooms and convention hall are equipped with the latest standard audio and visual facilities and also cater to live-streaming. we offer eco-meeting packages to stand along with our eco-conscious living throughout the ‘neera’ concept.";
    // const titleEl = document.querySelector("head title");
    const mTitleEl = document.querySelector('head meta[name="title"]');
    const descEl = document.querySelector('head meta[name="description"]');
    const twTitleEl = document.querySelector('head meta[name="twitter:title"]');
    const twDescEl = document.querySelector(
      'head meta[name="twitter:description"]'
    );
    const ogTitleEl = document.querySelector('head meta[property="og:title"]');
    const ogDescEl = document.querySelector('head meta[property="og:description"]');
    // titleEl.textContent = title;
    mTitleEl.setAttribute("content", title);
    twTitleEl.setAttribute("content", title);
    ogTitleEl.setAttribute("content", title);
    descEl.setAttribute("content", description);
    twDescEl.setAttribute("content", description);
    ogDescEl.setAttribute("content", description);
  },
  mounted() {
    AOS.init({
      once: true,
      duration: 2000,
    });
  },
  data() {
    return {
      navigations: [
        {
          name: "outdoor",
          link: "outdoor",
        },
        {
          name: "conventional hall",
          link: "conventional-hall",
        },
        {
          name: "1st-floor",
          link: "1st-floor",
        },
        {
          name: "2st floor",
          link: "2st-floor",
        },
      ],
      spaces: [
        {
          title: "neera green space",
          type: "outdoor garden",
          link: "outdoor",
          image_url: require("@/assets/experiences/spaces/experiences_03-neeraspaces.png"),
          description: "lush pet friendly outdoor garden zone",
          area: "340",
          addons: [
            "cocktail style : capacity TBC",
            "banquet style : capacity TBC",
          ],
        },
        {
          title: "at noon",
          type: "convention hall",
          link: "conventional-hall",
          image_url: require("@/assets/experiences/spaces/HOME_03slide-atnoon.png"),
          description: "daylight riverfront stand-alone convention hall",
          area: "182",
          addons: [
            "theatre style : capacity 150 pax",
            "classroom style : capacity 72 pax",
            "u-shape style : capacity 36 pax",
            "conference style : capacity 33 pax",
            "banquet style : capacity TBC",
            "cocktail style : capacity TBC",
          ],
        },
        {
          title: "at dawn",
          type: "2nd floor",
          link: "2nd-floor",
          image_url: require("@/assets/experiences/spaces/03_05_atdawn.png"),
          description: "meeting room with river view",
          area: "128",
          note: "can be separated into 2 rooms S–26sqm | M–102sqm",
          addons: [
            "theatre style : capacity 80 pax",
            "classroom style : capacity 36 pax",
            "u-shape style : capacity 33-46 pax",
            "conference style : capacity TBC",
            "banquet style : capacity 80",
            "cocktail style : capacity TBC",
          ],
        },
        {
          title: "at dusk",
          type: "1st floor",
          link: "1st-floor",
          image_url: require("@/assets/experiences/spaces/03_atdusk.png"),
          description:
            "meeting room with a private foyer to a view of our pocket garden",
          area: "107",
          addons: [
            "theatre style : capacity 80 pax",
            "classroom style : capacity 36 pax",
            "u-shape style : capacity 30 pax",
            "conference style : capacity 30 pax",
            "banquet style : capacity 60 pax",
            "cocktail style : capacity TBC",
          ],
        },
        {
          title: "at night",
          type: "1st floor",
          link: null,
          image_url: require("@/assets/experiences/spaces/03_04_atnight.png"),
          description: "small living room next to cafe with lush green view",
          area: "56",
          addons: [
            "theatre style : capacity 25 pax",
            "classroom style : capacity 32 pax",
            "u-shape style : capacity 15 pax",
            "conference style : capacity 14 pax",
            "banquet style : capacity TBC",
            "cocktail style : capacity TBC",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.title {
  font-family: "Saol Text" !important;
  font-style: normal;
  font-weight: 300;
  color: #905235;
}

.booking_button {
  color: #994e2e;
  border: 1px solid #994e2e;
  border-radius: 10px;
}

.menu-content {
  border-top: 1px solid #cecdc8;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

sup {
  top: -20px;
}
</style>
